import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import config from '../core/services/config';

// pages
import HomePage from '../pages/HomePage';
import UnsubscribePage from '../pages/UnsubscribePage';
import Footer from './Footer';

export default function Home() {

  const [tenant, setTenant] = useState('/');

  // on page load, set tenant based and load tenant based css
  // handle tenant based routes, https://stackoverflow.com/questions/55007809/dynamic-basename-with-browserrouter-in-react-router-dom
  useEffect((tenant) => {
    const parsedData = window.location.pathname.split("/");
    let domain = parsedData[1];
    const isExemptPath = domain && config && config.exemptPaths && config.exemptPaths.indexOf(domain.toLowerCase()) !== -1;

    if (!isExemptPath && domain !== tenant) {
      setTenant("/" + domain);
    }

    import(`../assets/tenants/${domain}/style.css`).catch(() => {
      import(`../assets/tenants/${config.defaultDomain}/style.css`).catch();
    });
  }, []);

  return (
    <div className="App">
      <div className="surface">
        <div className="container">
          <div className="page--form">
            <img className="logo" src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="logo" />
            <BrowserRouter basename={tenant}>
              <Routes>
                <Route path="/unsubscribe" element={<UnsubscribePage tenant={tenant} />} />
                <Route path="/" element={<HomePage tenant={tenant} />} />
              </Routes>
            </BrowserRouter>
            <hr />
            <img className="secure-logo" src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="Digicert Trusted Secure" />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
