import React from 'react';

export default class Footer extends React.Component {
  render() {
    return (
      <div className="btm copyright">
        <p className="subtext">©{new Date().getFullYear()} PaymentVision, a division of Autoscribe Corporation. All rights reserved.</p>
      </div>
    )
  }
}
