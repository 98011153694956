import './App.css';
import ConfigLoader from './components/ConfigLoader';
import Home from './components/Home';

function App() {
  return (
     // When the configuration is loaded, render the Home module
     <ConfigLoader ready={() => <Home />} />
  );
}

export default App;
