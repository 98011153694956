import React from 'react';

export default class HomePage extends React.Component {
  state = {
    id: ''
  }

  render() {
    return (
      <div>
        <h2>FAST & RELIABLE PAYMENT MESSAGING</h2>
        <p>
          This is a product site that is owned and operated by PaymentVision.  We offer a range products and services from bill presentment to settlement services.
        </p>
        <h2>About PaymentVision</h2>
        <p>
          PaymentVision, an Autoscribe company, is a financial services and payment processing company currently servicing thousands of financial institutions and corporate billers across the nation.
        </p>
        <p>
          PaymentVision electronic payment services offers a PCI-certified payment gateway that connects to the ACH and credit card network, which allows our clients to accept electronic payments via phone, web or their own contact center using our suite of payment channels or a payment API. Our sister company, Lyon’s Commercial Data is the premier provider of quality financial institution data including bank routing numbers and other information critical to transaction processing. Products from Lyons reduce risk and ensure compliance in an increasingly complex world where the cost of non-compliance cannot be measured in money.
        </p>
        <p>
          Among our growing roster of clients are a number of the largest U.S. financial institutions, credit unions, insurance companies, municipalities, credit bureaus, foreign exchange originators, as well as non-financial entities large and small.
        </p>
      </div>
    )
  }
}
