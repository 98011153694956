import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import config from '../core/services/config';

export default function UnsubscribePage() {

  const [message, setMessage] = useState('Unsubscribing you from notifications, please wait...');
  const [isRedirect, setIsRedirect] = useState(false);
  const [searchParams] = useSearchParams();
  const unsubscribeKey = searchParams.get('key');

  // make api call whenever notificationid changes
  useEffect(() => {

    if(unsubscribeKey) {
      axios.post(`${config.baseUrl}/api/UnsubscribeByAuditID`, { encryptAuditId: unsubscribeKey })
      .then((result) => {
        if(result && result.data && result.data.responses && result.data.responses.find( ({responseCode}) => responseCode === '1000' )) {
          setMessage(`${result.data.emailAddress || 'Your email'} has been removed from ${result.data.organizationName || ''} payment notifications.`);
        } else {
          setMessage(`Your email address could not be unsubscribed.`);
        }
      })
      .catch(error => {
        setMessage(`Your email address could not be unsubscribed.`);
      });
    } else {
      setIsRedirect(true);
    }

  }, [unsubscribeKey]);

  return (
    <div className="form-message">
      <p>{message}</p>
      { isRedirect ? <Navigate to="/" /> : '' }
    </div>
  );
}

